import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import KegIcon from "../icons/Keg";
import BottleThickIcon from "../icons/BottleThick";
import DrainageIcon from "../icons/Drainage";
import Sliders from "../components/Sliders"
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function Inputs(){
  return(
    <Grid container direction="column" justify="flex-start" alignItems="center">
      <h1>Switches</h1>
      <Switches />
      <h1>Sliders</h1>
      <Sliders />
      <h1>Radio Buttons</h1>
      <RadioButtons />
      <h1>Buttons</h1>
      <Buttons />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  btn: {
    margin: theme.spacing(0, 1, 1, 0)
  }
}));

const TooltipToggleButton = ({ children, title, ...props }) => (
  <Tooltip title={title}>
    <ToggleButton {...props}>{children}</ToggleButton>
  </Tooltip>
)

function RadioButtons(){
  const [containerType, setcontainerType] = React.useState('');

  const handlecontainerType = (event, newcontainerType) => {
    setcontainerType(newcontainerType);
  };

  return (
    <ToggleButtonGroup
      value={containerType}
      exclusive
      onChange={handlecontainerType}
      aria-label="Gebinde-Typ"
      size="large"
    >     
      <TooltipToggleButton value="keg" title="KEG" aria-label="KEG">
          <KegIcon fontSize="large" />
      </TooltipToggleButton>    
      <TooltipToggleButton value="bottle" title="Flasche" aria-label="Flasche">
          <BottleThickIcon fontSize="large" />
      </TooltipToggleButton>      
      <TooltipToggleButton value="drainage" title="Abfluss" aria-label="Abfluss">
          <DrainageIcon fontSize="large" />
      </TooltipToggleButton>      
    </ToggleButtonGroup>
  );
}

function Buttons(){
  const classes = useStyles();
  return(    
    <FormGroup row className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button className={classes.btn} variant="contained">Hi</Button>
        <Button className={classes.btn} variant="contained" color="primary">Hi</Button>
        <Button className={classes.btn} variant="contained" color="secondary">Hi</Button>
        <Button className={classes.btn} variant="contained" disabled>Hi</Button>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button className={classes.btn} variant="outlined">Hi</Button>
        <Button className={classes.btn} variant="outlined" color="primary">Hi</Button>
        <Button className={classes.btn} variant="outlined" color="secondary">Hi</Button>
        <Button className={classes.btn} variant="outlined" disabled>Hi</Button>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button className={classes.btn}>Hi</Button>
        <Button className={classes.btn} color="primary">Hi</Button>
        <Button className={classes.btn} color="secondary">Hi</Button>
        <Button className={classes.btn} disabled>Hi</Button>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button size="small" className={classes.btn}>Small</Button>
        <Button size="medium" className={classes.btn}>Medium</Button>
        <Button size="large" className={classes.btn}>Large</Button>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button variant="outlined" size="small" color="primary" className={classes.btn}>Small</Button>
        <Button variant="outlined" size="medium" color="primary" className={classes.btn}>Medium</Button>
        <Button variant="outlined" size="large" color="primary" className={classes.btn}>Large</Button>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button variant="contained" size="small" color="primary" className={classes.btn}>Small</Button>
        <Button variant="contained" size="medium" color="primary" className={classes.btn}>Medium</Button>
        <Button variant="contained" size="large" color="primary" className={classes.btn}>Large</Button>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <IconButton aria-label="delete" className={classes.btn} size="small">
          <DeleteIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="delete" className={classes.btn}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton aria-label="delete" className={classes.btn}>
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" className={classes.btn}>
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" disabled color="primary">
          <DeleteIcon />
        </IconButton>
        <IconButton color="secondary" aria-label="add an alarm">
          <DeleteIcon />
        </IconButton>
        <IconButton color="primary" aria-label="add to shopping cart">
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button variant="contained" color="primary" className={classes.btn} startIcon={<DeleteIcon />}>Text</Button>
        <Button variant="contained" color="primary" className={classes.btn} endIcon={<DeleteIcon />}>Text</Button>
      </Grid>
    </FormGroup>
  );
}

function Switches() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <FormGroup row className={classes.root}>
      <FormControlLabel control={<Switch checked={state.checkedA} onChange={handleChange} name="checkedA" />}
        label="Secondary"
      />
      <FormControlLabel control={<Switch checked={state.checkedB} onChange={handleChange} name="checkedB" color="primary"/>}
        label="Primary"
      />
      <FormControlLabel disabled control={<Switch />} label="Disabled" />
    </FormGroup>
  );
}

