import React, { Component } from "react";
import logo from '../logo.svg';

class Home extends Component {
  render() {
    return (
        <React.Fragment>
            <h1>Home</h1>
            <img src={logo} className="App-logo" alt="logo" />
        </React.Fragment>
    );
  }
}
 
export default Home;