import React from 'react';
import Drawer from "./components/Drawer";
import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TapBar from "./components/TapBar"

export default function App() {
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersLightMode ? 'light' : 'dark',
          primary: {
            main: prefersLightMode ? '#ff6f00' : '#ffee58',
          }, 
          secondary: {
            main: prefersLightMode ? '#03a9f4' : '#4fc3f7',
          },
        },
      }),
    [prefersLightMode],
  );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Drawer />
        <TapBar />
      </ThemeProvider>
    </div>
  );
}
