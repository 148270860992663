import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import loginPicture from '../login.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  image: {
    backgroundImage: `url(${loginPicture})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
},
}));

export default function SignInSide() {
  const classes = useStyles();

  const [state, setState] = useState({
    rememberLogin: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [user, setUser] = React.useState([]);

  useEffect(() => {
    const credentials = {
      "email": "felix@popp-mail.de",
      "password": "Ki3,Tng7",
      "mode": "cookie",
    };
    axios.post('https://api.craftcell.de/craftcell/auth/authenticate', credentials)        
        .then(response => {
          console.log(response);
            setIsLoaded(true);
            if(response.error){
                setError(response.error);
            } else {              
                setUser(response.data.data.user);
            }
        })
        .catch(error => {
          setIsLoaded(true);
          setError(error);
        });
       /* .then(res => res.json())
          .then(
          (result) => {
            setIsLoaded(true);
            if(result.error){
                setError(result.error);
            } else {
                setItems(result.data);
            }
            
        },
        (error) => {
            setIsLoaded(true);
            setError(error);
        }
        )*/
  }, [])

  if (error) {
    return <div>Error {error.code}: {error.message}</div>;
  } else if (!isLoaded) {
      return <div>Loading...</div>;
  } else {
      return (
          <h2>id: {user.id}, name: {user.first_name} {user.last_name}</h2>
      );
  }

  /*return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-Mail"
              name="email"
              autoFocus
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
            />
            <FormControlLabel
              control={<Switch checked={state.rememberLogin} onChange={handleChange} name="rememberLogin" color="primary"/>}
              label="Eingeloggt bleiben"
            />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Passwort vergessen?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  Kein Account? Registrieren
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );*/
}