import React from "react";

export default function ApiTest() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [items, setItems] = React.useState([]);
  
    React.useEffect(() => {
        fetch('https://api.craftcell.de/craftcell/items/sud')
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                if(result.error){
                    setError(result.error);
                } else {
                    setItems(result.data);
                }
                
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
            )
    }, [])
  
    if (error) {
        return <div>Error {error.code}: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <ul>
            {items.map(item => (
                <li key={item.id}>
                id: {item.id}, recipe_id: {item.recipe_id}
                </li>
            ))}
            </ul>
        );
    }
}