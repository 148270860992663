import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TabIcon from '@material-ui/icons/Tab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
    '& .MuiBottomNavigationAction-root': {
      '&.Mui-selected': {
        color: 'rgba(255, 255, 255, 0.7)',
      },
      '&.active': {
        color: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        }
      }
    }
  },
}));

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction component={NavLink} label="Home" value="/" exact to="/" icon={<HomeIcon />} />
      <BottomNavigationAction component={NavLink} label="Login" value="/login" to="/login" icon={<LockOpenIcon />} />
      <BottomNavigationAction component={NavLink} label="Inputs" value="/inputs" to="/inputs" icon={<CheckBoxIcon />} />
      <BottomNavigationAction component={NavLink} label="Tabs" value="/tabs" to="/tabs" icon={<TabIcon />} />
      <BottomNavigationAction component={NavLink} label="Cards" value="/cards" to="/cards" icon={<DashboardIcon />} />
    </BottomNavigation>
  );
}