import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Content from "./Content";
import AppBar from "./AppBar";
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TableChartIcon from '@material-ui/icons/TableChart';
import TodayIcon from '@material-ui/icons/Today';
import TabIcon from '@material-ui/icons/Tab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { NavLink } from 'react-router-dom';

const drawerWidth = 200;
const menuBreakpoint = 'xs';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down(menuBreakpoint)]: {
        width: 0,
    },
    [theme.breakpoints.up(menuBreakpoint)]: {
        width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  customListItem: {
    paddingLeft: theme.spacing(2),
    '&.active': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: `${theme.spacing(2) - 4}px`,
      color: theme.palette.primary.main,
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      }
    }
  },
}));

export default function ResponsiveDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(menuBreakpoint));

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (e) => {
    setOpen(false);
  };

  const handleDrawerListClick = () => {
    if(isSmallScreen) setOpen(false);
  }

  const drawerMenu = (
    <div>
        <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
        </div>
        <Divider />
        <List>
            <ListItem button className={classes.customListItem} component={NavLink} onClick={handleDrawerListClick} exact to="/" key="Home">
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem button className={classes.customListItem} component={NavLink} onClick={handleDrawerListClick} to="/login" key="Login">
                <ListItemIcon><LockOpenIcon /></ListItemIcon>
                <ListItemText primary="Login" />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button className={classes.customListItem} component={NavLink} onClick={handleDrawerListClick} to="/inputs" key="Inputs">
                <ListItemIcon><CheckBoxIcon /></ListItemIcon>
                <ListItemText primary="Inputs" />
            </ListItem>
            <ListItem button className={classes.customListItem} component={NavLink} onClick={handleDrawerListClick} to="/tabs" key="Tabs">
                <ListItemIcon><TabIcon /></ListItemIcon>
                <ListItemText primary="Tabs" />
            </ListItem>
            <ListItem button className={classes.customListItem} component={NavLink} onClick={handleDrawerListClick} to="/cards" key="Cards">
                <ListItemIcon><DashboardIcon /></ListItemIcon>
                <ListItemText primary="Cards" />
            </ListItem>
            <ListItem button className={classes.customListItem} component={NavLink} onClick={handleDrawerListClick} to="/tables" key="Tables">
                <ListItemIcon><TableChartIcon /></ListItemIcon>
                <ListItemText primary="Tables" />
            </ListItem>
            <ListItem button className={classes.customListItem} component={NavLink} onClick={handleDrawerListClick} to="/dateTimePickers" key="Date Pickers">
                <ListItemIcon><TodayIcon /></ListItemIcon>
                <ListItemText primary="Date Pickers" />
            </ListItem>            
        </List>
    </div>
  );

  return (
    <div className={classes.root}>
        <CssBaseline />
        <AppBar open={open} onClickMenuBtn={handleDrawerOpen} drawerWidth={drawerWidth} isSmallScreen={isSmallScreen}/>
        <nav aria-label="Hauptmenü">
            <Drawer
              variant={isSmallScreen ? "temporary" : "permanent"}
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={open}
              onClose={handleDrawerClose}
              className={clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open, })}
              classes={{ paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open, }), }}
              ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
              }}
            >
            {drawerMenu}
            </Drawer>
        </nav>
        <div className={classes.content}>
            <div className={classes.toolbar} />
            <Content />
        </div>
    </div>
  );
}