import React, { Component } from "react";
import Card from "../components/Card"
 
class Cards extends Component {
  render() {
    return (
        <React.Fragment>
            <Card />
            <Card />
        </React.Fragment>
    );
  }
}
 
export default Cards;