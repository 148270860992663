import React, { Component } from "react";
import MyTable from "../components/Table"
 
class Table extends Component {
  render() {
    return (
        <MyTable />
    );
  }
}
 
export default Table;