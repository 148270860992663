import React from 'react';
import { Route, Switch} from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Inputs from "../test-pages/Inputs";
import Tabs from "../test-pages/Tabs"
import Cards from "../test-pages/Cards"
import Tables from "../test-pages/Tables"
import DateTimePickers from "../test-pages/DateTimePickers"
import ApiTest from "../test-pages/Api"
import ErrorNotFound from "../pages/ErrorNotFound"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(3),
    justifyContent: 'center',
  },
}));

export default function Content() {
  const classes = useStyles();
  return(
    <div className={classes.root}>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/login" component={Login}/>
        <Route path="/inputs" component={Inputs}/>
        <Route path="/tabs" component={Tabs}/>
        <Route path="/cards" component={Cards}/>
        <Route path="/tables" component={Tables}/>
        <Route path="/dateTimePickers" component={DateTimePickers}/>
        <Route path="/apitest" component={ApiTest}/>
        <Route component={ErrorNotFound}/>
      </Switch>
    </div>
  );
}