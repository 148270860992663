import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import {
    KeyboardDatePicker,
    KeyboardTimePicker ,
    KeyboardDateTimePicker ,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de";

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

export default function DateTimePickers(){
    const classes = useStyles();
    const [selectedDate1, handleDateChange1] = useState(new Date());
    const [selectedDate2, handleDateChange2] = useState(new Date());
    const [selectedDate3, handleDateChange3] = useState(new Date());

    return(
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <KeyboardDatePicker className={classes.paper} value={selectedDate1} onChange={handleDateChange1} autoOk inputVariant="outlined" format={"dd.MM.yyyy"} cancelLabel={"Abbrechen"}/>
                <KeyboardTimePicker className={classes.paper} value={selectedDate2} onChange={handleDateChange2} ampm={false} cancelLabel={"Abbrechen"}/>
                <KeyboardDateTimePicker className={classes.paper} value={selectedDate3} onChange={handleDateChange3} ampm={false} format="dd.MM.yyyy HH:mm" cancelLabel={"Abbrechen"}/>
            </MuiPickersUtilsProvider>
        </Grid>
    );
};